<template>
  <div
    class="cardCardCheckoutView"
    v-loading="otherPayLoading"
    element-loading-text="充值中..."
  >
    <div class="leftView" style="margin-right: 480px;">
      <div class="topView">
        <label>支付</label>
        <button class="btn-back" @click="childMethod"></button>
      </div>
      <div class="contentView listView" style="height: 620px;">
        <div class="orderInfoView">
          <div class="timeView">
            办卡时间：{{ $moment().format("YYYY-MM-DD HH:mm") }}
          </div>
          <div class="cardView">
            <label>卡片名称：{{ cardName }}</label>
          </div>
          <div class="toPayView">
            <div class="amtItem subItem">
              <label class="label-name">
                <span>办卡应收</span>
              </label>
              <label class="label-amt">
                <span>¥ {{ cardPrice }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="paymentView">
          <div class="tView">支付方式</div>
          <div class="listView">
            <div
              class="paymentItem listItem"
              v-for="(item, index) in pay_ways"
              :key="index"
              @click="handlePayWay(item)"
            >
              <div class="iconView">
                <img :src="item.ico" v-if="item.label != 12" />
                <span
                  v-else
                  class="iconfont icon-31saoma"
                  style="font-size:40px"
                ></span>
              </div>
              <div class="textView overflowText">{{ item.name }}</div>
              <div class="selectView" v-if="payWay === item.label">
                <img
                  src="https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_Black.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="btnView">
        <div class="btn-settle" v-if="payWay !== 6" @click="payOrder">结账</div>
      </div> -->
    </div>
    <div class="rightView" style="width: 480px;">
      <div class="defImgView">
        <img
          src="https://static.bokao2o.com/wisdomDesk/images/Def_Bc_OrderCheckout_Right.png"
        />
        <p>{{ orderCheckoutVal }}</p>
      </div>
      <transition name="paidFullscreen">
        <div
          id="qrCodePayPopView"
          class="popView"
          style="width: 480px;"
          v-loading="qrCodePayView"
          :element-loading-text="qrCodePayViewName"
          v-if="payWayView"
        >
          <div class="popView-bcView" @click="handleClosePayWay"></div>
          <div
            class="popView-contentView"
            style="width: 450px; top: 0; right: 0; overflow: auto;"
          >
            <div class="qrCodePayView" style="height: 794px;">
              <div
                class="contentView"
                style="height: 513px;"
                v-loading="payLoading"
                :element-loading-text="qrCodePayViewName"
              >
                <div class="amtView">
                  ¥ <span>{{ cardPrice }}</span>
                </div>
                <!-- <div class="imgView">
                  <img src="https://www.shouqianba.com/img/logo.svg" />
                </div> -->
                <div class="qrCodeInputView">
                  <el-input
                    class="input-scanPayCode"
                    placeholder="请扫描或输入付款码"
                    autocomplete="off"
                    ref="pay"
                   v-model="payVal"
                      type="number"
                    autofocus="autofocus"
                  ></el-input>
                  <el-button @click="payOrder" type="primary">收款</el-button>
                </div>
                <div class="tipView">
                  请使用支付宝/微信扫码支付
                  <p>支付过程中，请不要关闭该二维码！</p>
                </div>
              </div>
            </div>
            <!-- 关闭收款右侧页面 -->
            <button class="btn-close" @click="payWayView = false"></button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  payCardQuery,
  payOrder,
  payRechargeQuery,
  payRecharge,
} from "@/api/member/user";

export default {
  name: "cardCheckoutView",
  props: {
    payObject: {
      type: Object,
    },
  },
  data() {
    return {
      cardName: "",
      qrCodePayView: false,
      qrCodePayViewName: "收款中...",
      payWay: "",
      payWayView: false,
      // 支付方式
      pay_ways: [
        {
          name: "扫码支付",
          label: 12,
        },
      ],
      // 支付二维码
      payVal: "",
      myInterval: null,
      shouInterval: null,
      payPrice: 0,
      applyCardForm: {
        userId: "",
        userType: "",
        phone: "",
        phone1: "",
        cardNo: "",
        realName: "",
        gender: "",
        payPrice: 0,
        payCode: "",
        cardId: 0,
        cardName: "",
        channel: 1,
        payWay: 12,
        payWayName: "扫码支付",
        source: 1,
        address: [], // 省市区code
        pcdAddress: "", // 省市区地址
        addressDetail: "", // 详细地址
        birthday: "",
      },
      payLoading: false,
      outTradeNo: "",
      cardType: "",
      orderCheckoutVal: "请选择结账方式",
      otherPayLoading: false,
      // 升级标识
      upgradeFlag: 0,
    };
  },
  created() {
    console.log(this.payObject);
    this.cardName = this.payObject.cardName;
    this.cardPrice = this.payObject.cardPrice;
    this.userRecordId = this.payObject.userRecordId;
    this.cardType = this.payObject.cardType;
  },
  methods: {
    childMethod() {
      this.$emit("handleCloseCardPay");
    },
    // 处理点击支付
    handlePayWay(item) {
      this.payWay = item.label;
      // this.applyCardForm.payWay = this.payWay
      if (item.label === 6) {
        this.payWayView = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }
      if (item.label === 12) {
        this.payWayView = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }
      if (item.label === 4) {
        this.orderCheckoutVal =
          "请使用" + item.name + "收款: " + this.cardPrice;
      }
    },
    // 关闭支付方式
    handleClosePayWay() {
      this.payWayView = false;
      this.payWay = "";
    },
    payOrder() {
      if (this.payWay !== 4 && !this.payVal) {
        this.$message.error("请输入付款码");
        return false;
      }
      if (!this.payWay) {
        this.$message.error("请选择支付方式");
        return false;
      }
      if (this.payWay == 4) {
        this.otherPayLoading = true;
      } else {
        this.payLoading = true;
      }
      let payWay = this.payWay;
      let userRecordId = this.userRecordId;
      let authCode = this.payVal;
      let data = {
        payWay,
        userRecordId,
        authCode,
      };
      if (this.payObject.businessType != 4) {
        payOrder(data)
          .then((response) => {
            console.log(response);
            // this.isPrint = false;
            if (response.data.code === 0) {
              // 只有微信 支付宝有回调
              if (
                payWay === 1 ||
                payWay === 2 ||
                payWay === 6 ||
                payWay === 12
              ) {
                this.outTradeNo = response.data.data.outOrderNo;
                this.myInterval = setInterval(() => {
                  this.queryPayResult(this.outTradeNo); //调用接口的方法
                }, 3000);
              } else {
                this.commonPaySuccess();
              }
            } else {
              if (
                payWay === 1 ||
                payWay === 2 ||
                payWay === 6 ||
                payWay === 12
              ) {
                clearInterval(this.shouInterval);
                if (response.data.data) {
                  // 需要用户支付
                  if (response.data.data.subCode === "WAITING_PAYMENT") {
                    this.outTradeNo = response.data.data.outOrderNo;
                    this.myInterval = setInterval(() => {
                      this.queryPayResult(this.outTradeNo); //调用接口的方法
                    }, 3000);
                  } else {
                    this.payLoading = false;
                    this.qrCodePayView = false;
                    this.$message.error(response.data.data.subMsg);
                    this.closePayModal();
                  }
                } else {
                  this.payLoading = false;
                  this.qrCodePayView = false;
                  this.$message.error(response.data.data.subMsg);
                  this.closePayModal();
                }
              } else {
                this.payLoading = false;
                this.qrCodePayView = false;
                if (response.data.data) {
                  this.$message.error(response.data.data.subMsg);
                } else {
                  this.$message.error(response.data.msg);
                }
                this.closePayModal();
                this.otherPayLoading = false;
                this.$message.error(response.data.data.subMsg);
              }
            }
          })
          .catch((reason) => {
            this.payLoading = false;
            this.qrCodePayView = false;
            // 要进行跳转到当前未支付的订单
            console.log(reason);
            this.closePayModal();
          });
      } else {
        payRecharge(data)
          .then((response) => {
            console.log(response);
            // this.isPrint = false;
            if (response.data.code === 0) {
              // 只有微信 支付宝有回调
              if (
                payWay === 1 ||
                payWay === 2 ||
                payWay === 6 ||
                payWay === 12
              ) {
                this.outTradeNo = response.data.data.outOrderNo;
                this.myInterval = setInterval(() => {
                  this.queryPayResult(this.outTradeNo); //调用接口的方法
                }, 3000);
              } else {
                this.commonPaySuccess();
              }
            } else {
              if (
                payWay === 1 ||
                payWay === 2 ||
                payWay === 6 ||
                payWay === 12
              ) {
                clearInterval(this.shouInterval);
                if (response.data.data) {
                  // 需要用户支付
                  if (response.data.data.subCode === "WAITING_PAYMENT") {
                    this.outTradeNo = response.data.data.outOrderNo;
                    this.myInterval = setInterval(() => {
                      this.queryPayResult(this.outTradeNo); //调用接口的方法
                    }, 3000);
                  } else {
                    this.payLoading = false;
                    this.qrCodePayView = false;
                    this.$message.error(response.data.data.subMsg);
                    this.closePayModal();
                  }
                } else {
                  this.payLoading = false;
                  this.qrCodePayView = false;
                  this.$message.error(response.data.data.subMsg);
                  this.closePayModal();
                }
              } else {
                this.payLoading = false;
                this.qrCodePayView = false;
                if (response.data.data) {
                  this.$message.error(response.data.data.subMsg);
                } else {
                  this.$message.error(response.data.msg);
                }
                this.closePayModal();
                this.otherPayLoading = false;
                this.$message.error(response.data.data.subMsg);
              }
            }
          })
          .catch((reason) => {
            this.payLoading = false;
            this.qrCodePayView = false;
            // 要进行跳转到当前未支付的订单
            console.log(reason);
            this.closePayModal();
          });
      }

      if (this.applyCardForm.payWay === 6 || this.applyCardForm.payWay === 12) {
        this.qrCodePayViewName = "等待用户输入密码...";
      }
    },
    // 查询支付结果
    queryPayResult(userRecordId) {
      const params = new URLSearchParams();
      params.append("payWay", this.payWay);
      params.append("outTradeNo", userRecordId);
      if (this.payObject.businessType != 4) {
        payCardQuery(params)
          .then((response) => {
            console.log(response);
            // 微信
            if (response.data.code == 0) {
              if (response.data.data.subCode === "SUCCESS") {
                clearInterval(this.shouInterval);
                this.commonPaySuccess();
              } else if (response.data.data.subCode == "WAITING_PAYMENT") {
                this.qrCodePayViewName = "等待用户输入密码";
              } else {
                this.qrCodePayView = false;
                this.payLoading = false;
                this.$message.error(response.data.data.subMsg);
                this.applyCardForm.payCode = null;
                clearInterval(this.myInterval);
              }
            } else {
              this.$message.error(response.data.msg);
              this.qrCodePayView = false;
              this.payLoading = false;
              clearInterval(this.myInterval);
            }
          })
          .catch(() => {
            this.qrCodePayView = false;
            this.payLoading = false;
            // this.qrCodePayView = false;
            clearInterval(this.myInterval);
          });
      } else {
        payRechargeQuery(params)
          .then((response) => {
            console.log(response);
            // 微信
            if (response.data.code == 0) {
              if (response.data.data.subCode === "SUCCESS") {
                clearInterval(this.shouInterval);
                this.commonPaySuccess();
              } else if (response.data.data.subCode == "WAITING_PAYMENT") {
                this.qrCodePayViewName = "等待用户输入密码";
              } else {
                this.qrCodePayView = false;
                this.payLoading = false;
                this.$message.error(response.data.data.subMsg);
                this.applyCardForm.payCode = null;
                clearInterval(this.myInterval);
              }
            } else {
              this.$message.error(response.data.msg);
              this.qrCodePayView = false;
              this.payLoading = false;
              clearInterval(this.myInterval);
            }
          })
          .catch(() => {
            this.qrCodePayView = false;
            this.payLoading = false;
            // this.qrCodePayView = false;
            clearInterval(this.myInterval);
          });
      }
    },
    // 公共的
    commonPaySuccess() {
      this.qrCodePayView = false;
      this.$message({
        message: "办卡成功!",
        type: "success",
      });
      clearInterval(this.myInterval);
      this.$router.push({
        name: "applyCardSuccess",
        query: {
          userRecordId: this.userRecordId,
        },
      });
    },
    // 关闭支付框
    closePayModal() {
      if (this.orderNumber) {
        clearInterval(this.myInterval);
        clearInterval(this.shouInterval);
        this.$router.push({
          path: "/home/more/applyCardList",
          query: {
            orderNumber: this.orderNumber,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep input[type="number"]{
    -moz-appearance: textfield;
}
.cardCardCheckoutView {
  position: fixed;
  display: flex;
  top: 0;
  left: 70px;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  z-index: 100;
  width: calc(100vw - 70px);

  > .leftView {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;

    > .topView {
      position: relative;
      background: #fff;
      padding: 25px 0 15px 0;
      text-align: center;

      > label {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        line-height: 40px;
      }

      > .btn-back {
        position: absolute;
        top: 25px;
        left: 15px;
        width: 40px;
        height: 40px;
        border: none;
        outline: none;
        background: url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_NavBack.png)
          no-repeat left center;
        background-size: 28px;
      }
    }

    > .contentView {
      overflow-x: hidden;
      overflow-y: auto;

      > .orderInfoView .subItem {
        display: flex;
        padding: 12px 25px 12px 20px;
        line-height: 20px;

        > label {
          flex: 1;
          height: 20px;
        }

        > .label-name {
          flex: 2;
          color: #666;

          > span {
            font-family: PingFangSC-Semibold;
            font-size: 15px;
            color: #333;
            margin-right: 15px;
          }
        }

        > .label-amt {
          text-align: right;
          font-size: 15px;
          color: #333;
          font-family: DINAlternate-Bold;
        }
      }

      > .orderInfoView {
        padding: 0 30px 15px 30px;

        > .timeView {
          line-height: 30px;
          font-size: 14px;
          color: #666;
        }

        > .cardView {
          line-height: 32px;
          color: #333;
          font-size: 14px;

          > label {
            margin-right: 35px;
          }

          > label:last-child {
            margin-right: 0;
          }
        }

        > .toPayView {
          padding: 5px 0;
          margin-top: 15px;
          background: #f8f8f8;
          border-radius: 6px;
          overflow: hidden;
        }
      }

      > .paymentView {
        padding: 0 24px 0 24px;

        > .tView {
          margin: 0 6px;
          line-height: 40px;
          font-size: 15px;
          font-family: PingFangSC-Semibold;
          color: #333;
          border-top: 0.5px solid #ddd;
        }

        > .listView {
          overflow: hidden;

          > .paymentItem {
            position: relative;
            float: left;
            width: 87px;
            height: 87px;
            border-radius: 6px;
            background: #f8f8f8;
            padding: 10px 0;
            text-align: center;
            margin: 0 6px 10px 6px;
            overflow: hidden;

            > .iconView {
              height: 40px;

              > img {
                width: 40px;
                height: 40px;
              }

              > .label-icon {
                display: inline-block;
                border-radius: 6px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 18px;
                font-family: PingFangSC-Medium;
                color: #fff;
                text-align: center;
                background: rgba(66, 153, 255, 0.7);
              }
            }

            > .textView {
              margin-top: 8px;
              line-height: 20px;
              font-size: 14px;
              color: #333;
            }

            > .selectView {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(40, 40, 45, 0.4);
              text-align: center;
              border-radius: 6px;

              > img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }

    > .btnView {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      padding: 20px 0;
      text-align: center;
      background: #fff;

      > .btn-settle {
        display: inline-block;
        width: 260px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        background: #3370ff;
        border-radius: 6px;
      }
    }
  }

  > .rightView {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: #fff
      url(https://static.bokao2o.com/wisdomDesk/images/Def_OrderCheckout_RightBorder.png)
      left center / 27px no-repeat;
    z-index: 10;

    > .defImgView {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      height: 180px;

      > img {
        width: 192px;
        height: 150px;
      }

      > p {
        margin-top: 10px;
        color: #666;
        line-height: 20px;
      }
    }
  }
}

#qrCodePayPopView {
  position: fixed;
  left: auto;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    background: transparent;
  }

  > .popView-contentView {
    border-radius: 0;
    box-shadow: none;
  }

  > .popView-contentView .qrCodePayView {
    position: relative;
    background: #fff;
    padding: 0 60px 0 30px;

    > .contentView {
      position: absolute;
      left: 30px;
      right: 60px;
      top: 0;
      bottom: 0;
      margin: auto;
      text-align: center;

      > .amtView {
        line-height: 50px;
        font-size: 32px;
        color: #ff5e56;

        > span {
          font-size: 36px;
          margin-left: 5px;
        }
      }

      > .imgView {
        margin-top: 20px;
        height: 46px;

        > img {
          width: 120px;
          height: 46px;
        }
      }

      > .qrCodeInputView {
        display: flex;
        padding: 30px 20px 20px 20px;

        > .input-scanPayCode {
          flex: 1;
          margin-right: 5px;
        }
      }

      > .tipView {
        line-height: 20px;
        font-size: 14px;
        color: #b0b0b0;
      }
    }
  }
}

//动画过程
.paidFullscreen-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave {
  animation: paidFullscreen-dialog-fade-out 0.9s ease forwards;
}

.paidFullscreen-enter-active {
  animation: paidFullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave-active {
  animation: paidFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes paidFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes paidFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;

  > .popView-bcView {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  > .popView-contentView {
    position: absolute;
    overflow: auto;
    min-height: 200px;
    background: #fff;
    border-radius: 8px;

    > .btn-close {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 40px;
      height: 40px;
      background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png)
        center / 28px 28px no-repeat;
      border: none;
      outline: none;
    }
  }
}
</style>
