import request from "@/utils/request";

//获取权益卡列表
export function getCardList(params) {
  return request({
    url: "member/membercard/list",
    method: "get",
    params: params,
  });
}
//获取会员卡
export function getMemberCardInfoByCardId(cardId) {
  return request({
    url: "member/membercard/getMemberCardInfoByCardId/" + cardId,
    method: "get",
  });
}
// 分页查询办卡记录
export function memberCardUserRecordPage(params) {
  return request({
    url: "member/membercard/record/memberCardUserRecordPage",
    method: "get",
    params: params,
  });
}

//获取办卡信息
export function getApplyCardInfo(userRecordId) {
  return request({
    url: "member/user/getApplyCardInfo/" + userRecordId,
    method: "get",
  });
}

//获取会员卡信息
export function getQueryCardList(params) {
  return request({
    url: "member/membercard/query",
    method: "get",
    params,
  });
}
// 查询门店可选，已选会员卡列表
export function getMembercardList(params) {
  return request({
    url: "member/membercard/store/list",
    method: "get",
    params,
  });
}
// 查询门店可选，已选通享卡卡列表
export function getRefillCardList(params) {
  return request({
    url: "member/membercard/store/universalList",
    method: "get",
    params,
  });
}
// 修改门店卡关联
export function updateStroeCard(data) {
  return request({
    url: "member/card/store/update",
    method: "put",
    data,
  });
}
