<template>
  <div class="userView content-index">
    <div class="topView">
      <div class="contentView">
        <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="办卡列表"
        ></el-page-header>

        <div class="filterView">
          <div class="dateView">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              size="medium"
            ></el-date-picker>
          </div>
          <!-- <div class="orderTypeView">
            <el-select
              v-model="userType"
              size="medium"
              placeholder="用户类型"
              clearable
              @clear="handleFind"
            >
              <el-option
                v-for="(item, index) in userTypeOptions"
                :key="index"
                :label="item.label"
                :value="item.val"
              ></el-option>
            </el-select>
          </div> -->
          <div class="orderTypeView">
            <el-select
              v-model="registerWay"
              clearable
              size="medium"
              placeholder="注册方式"
              @clear="handleFind"
            >
              <el-option
                v-for="(item, index) in registerWayOptions"
                :key="index"
                :label="item.label"
                :value="item.val"
              ></el-option>
            </el-select>
          </div>
          <div class="orderTypeView">
            <el-select
              v-model="userStatus"
              clearable
              placeholder="用户状态"
              size="medium"
              @clear="handleFind"
            >
              <el-option
                v-for="(item, index) in userStatusOptions"
                :key="index"
                :label="item.label"
                :value="item.val"
              ></el-option>
            </el-select>
          </div>
          <div class="keywordView">
            <el-input
              v-model="queryContent"
              size="medium"
              clearable
              @clear="handleFind"
              placeholder="请输入用户手机号"
            ></el-input>
          </div>
          <div class="keywordView">
            <el-button type="success" @click="handleFind" size="medium">
              确认
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="contentView">
      <div class="headerView flexView">
        <label>流水号</label>
        <label>业务类型</label>
        <label>联系电话</label>
        <label>姓名</label>
        <label>卡名称</label>
        <label>卡金额</label>
        <label>支付金额</label>
        <label>支付方式</label>
        <label>支付状态</label>
        <label>办卡时间</label>
      </div>
      <div
        class="listView"
        style="height:calc(100vh - 300px)"
        ref="rightScroll"
      >
        <div
          class="listItem flexView"
          v-for="(item, index) in userArr"
          :key="index"
        >
          <label>{{ item.id }}</label>
          <label>{{ item.businessTypeName }}</label>
          <label v-hidephone>{{ item.phone }}</label>
          <label>{{ item.realName }}</label>
          <label>{{ item.cardName }}</label>
          <label>{{ item.cardPrice }}</label>
          <label>{{ item.payPrice }}</label>
          <label>{{ item.payWayStr }}</label>
          <label>
            <el-tag type="danger" v-if="item.payStateStr == '未支付'">
              {{ item.payStateStr }}
            </el-tag>
            <el-tag type="warning" v-else-if="item.payStateStr == '已退款'">
              {{ item.payStateStr }}
            </el-tag>
            <el-tag v-else>{{ item.payStateStr }}</el-tag>
          </label>
          <label>{{ item.createTime }}</label>
          <el-button-group
            class="btn-edit"
            v-if="item.payStateStr == '未支付' && (item.payWay == '12' || item.payWay == '6')"
          >
            <el-button
              type="danger"
              size="small"
              style="margin-right: 1px;"
              @click="handlePay(item)"
            >
              去支付
            </el-button>
            <el-button
              type="primary"
              size="small"
              style="margin-right: 1px;"
              @click="
                queryPayResult(
                  item.payWay,
                  item.tradeNo,
                  item.id,
                  item.businessType
                )
              "
            >
              去查询
            </el-button>
          </el-button-group>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!--支付页面-->
    <transition name="payFullscreen">
      <cardCheckoutView
        v-if="q"
        :pay-object="payObject"
        @handleCloseCardPay="handleCloseCardPay"
      ></cardCheckoutView>
    </transition>
  </div>
</template>

<script>
import { memberCardUserRecordPage } from "@/api/member/card";
import CardCheckoutView from "@/view/card/components/cardCheckoutView";
import { payCardQuery, payRechargeQuery } from "@/api/member/user";

export default {
  name: "index",
  components: {
    CardCheckoutView,
  },
  props: {},
  data() {
    return {
      currentTab: 0,
      q: false,
      memberBaseInfoView: true,
      orderListView: false,
      outFactory: false,
      reWash: false,
      timeOut: false,
      rightScrollHeight: 0,
      userTypeOptions: [
        {
          label: "所有",
          val: "0",
        },
        {
          label: "散客",
          val: "1",
        },
        {
          label: "权益会员",
          val: "2",
        },
        {
          label: "充值会员",
          val: "3",
        },
      ],
      timeStart: "",
      timeEnd: "",
      registerWayOptions: [
        {
          label: "门店顾客",
          val: "1",
        },
        {
          label: "小程序顾客",
          val: "2",
        },
      ],
      userStatus: "",
      queryContent: "",
      userType: "",
      registerWay: "",
      userStatusOptions: [
        {
          label: "正常",
          val: "0",
        },
        {
          label: "禁用",
          val: "1",
        },
        {
          label: "注销",
          val: "2",
        },
      ],
      userArr: [],
      dateRange: [
        this.$moment().format("YYYY-MM-DD"),
        this.$moment().format("YYYY-MM-DD"),
      ],
      loadingUserArr: false,
      noMoreUserArr: false,
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总数量
      moreList: [],
      selectIndex: null, //搜索结果,被选中的li index
      userDetail: {
        userId: "",
        userType: 1,
        phone: "",
        birthday: "",
        realName: "",
        userName: "",
        avator: "",
        email: "",
        score: 0,
        totalScore: 0,
        state: 0,
        consumptionFrequency: 0,
        userQuality: 0,
        createTime: "",
        memberUser: {
          cardNo: "",
          balance: 0,
        },
        totalOrderNum: 0,
        totalConsumption: 0,
        avgOrderPrice: 0,
        recordList: [],
        orderList: [],
      },
      userId: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      userRecordId: "",
      payObject: {},
    };
  },
  created() {
    this.loadUserArr();
  },
  computed: {},

  mounted() {},
  methods: {
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.loadUserArr();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.loadUserArr();
    },
    toggleTab: function(index) {
      this.currentTab = index;
    },
    handleDateFind(time) {
      if (time === 0) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(time);
      }
      if (time === -1) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(time);
      }
      if (time === -7) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(0);
      }
      if (time === -30) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(0);
      }

      this.handleFind();
    },
    handleFind: function() {
      this.userArr = [];
      this.currentPage = 1;
      this.loadUserArr();
    },
    async loadUserArr() {
      const params = new URLSearchParams();
      params.append("current", this.currentPage);
      params.append("size", this.pageSize);
      params.append(
        "beginTime",
        this.dateRange === null ? "" : this.dateRange[0]
      );
      params.append(
        "endTime",
        this.dateRange === null ? "" : this.dateRange[1]
      );
      params.append("registerWay", this.registerWay);
      params.append("userType", this.userType);
      params.append("userStatus", this.userStatus);
      params.append("phone", this.queryContent);

      const { data: res } = await memberCardUserRecordPage(params);
      if (res.code !== 0) return this.$message.error("数据获取失败!");
      this.total = res.data.total;
      this.userArr = res.data.records;
    },
    handleUserDetail(userId) {
      this.q = true;
      this.userId = userId;
      // this.getUserDetailById()
    },
    handlePay(item) {
      this.payObject = {
        userRecordId: item.id,
        cardName: item.cardName,
        cardPrice: item.payPrice,
        cardType: item.cardType,
        businessType: item.businessType,
      };
      this.q = true;
    },
    handleCloseCardPay() {
      this.q = false;
    },
    // 查询支付结果
    queryPayResult(payWay, outTradeNo, userRecordId, businessType) {
      this.userRecordId = userRecordId;
      const params = new URLSearchParams();
      params.append("payWay", payWay);
      params.append("outTradeNo", outTradeNo);
      if (businessType != 4) {
        payCardQuery(params).then((response) => {
          if (response.data.code == 0) {
            // 微信
            if (response.data.data.success) {
              if (response.data.data.subCode === "SUCCESS") {
                clearInterval(this.shouInterval);
                this.commonPaySuccess();
              } else if (response.data.data.subCode === "USERPAYING") {
                this.qrCodePayViewName = "等待用户输入密码";
              } else {
                this.qrCodePayView = false;
                this.$message.error(response.data.data.subMsg);
              }
            } else {
              this.$message.error(response.data.data.subMsg);
            }
          } else {
            this.$message.error(response.data.msg);
          }
        });
      } else {
        payRechargeQuery(params).then((response) => {
          if (response.data.code == 0) {
            // 微信
            if (response.data.data.success) {
              if (response.data.data.subCode === "SUCCESS") {
                clearInterval(this.shouInterval);
                this.commonPaySuccess();
              } else if (response.data.data.subCode === "USERPAYING") {
                this.qrCodePayViewName = "等待用户输入密码";
              } else {
                this.qrCodePayView = false;
                this.$message.error(response.data.data.subMsg);
              }
            } else {
              this.$message.error(response.data.data.subMsg);
            }
          } else {
            this.$message.error(response.data.msg);
          }
        });
      }
    },
    // 公共的
    commonPaySuccess() {
      this.qrCodePayView = false;
      this.$message({
        message: "充值成功!",
        type: "success",
      });
      this.$router.push({
        name: "applyCardSuccess",
        query: {
          userRecordId: this.userRecordId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  padding: 15px 0;
  text-align: right;
}

.userView {
  margin: 0;
  padding: 0 35px 20px 30px;

  > .topView {
    background: #fff;

    > .contentView {
      position: relative;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 35px;
        width: 120px;
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .filterView {
        display: flex;
        height: 44px;
        padding: 15px 0 0 0;

        > div {
          height: 44px;
          margin-right: 15px;
        }

        > .keywordView {
          > button {
            background: #3370ff;
            font-size: 16px;
            font-family: PingFangSC-Medium;
            color: #fff;
          }
        }
      }
    }
  }

  > .contentView {
    text-align: center;

    > .headerView {
      line-height: 40px;
      padding: 0 140px 0 25px;
      font-size: 13px;
      color: #666;
      text-align: center;
    }

    > .listView {
      // height:100vh;
      // overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 25px;

      > .listItem {
        position: relative;
        // line-height: 20px;
        font-size: 13px;
        color: #333;
        background: #f8f8f8;
        text-align: center;

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 36px;
        }
      }

      > .moreItem {
        margin-top: 5px;
        line-height: 40px;
        font-size: 13px;
        color: #3370ff;
        text-align: center;
      }

      > .listItem:nth-child(odd) {
        background: #fff;
      }
    }
  }

  > .contentView .flexView {
    display: flex;
    padding: 15px 140px 15px 25px;

    > label {
      flex: 1;
    }

    > label:nth-child(1) {
      flex: 1.2;
    }

    > label:nth-child(3) {
      flex: 1.2;
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.memberDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: "";
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }

      > .btnView {
        width: 132px;
        display: flex;

        > button {
          flex: 1;
          margin-right: 12px;
          height: 40px;
          background: #47bf7c;
          color: #fff;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          border-radius: 4px;
        }

        > button:last-child {
          margin-right: 0;
          background: #3370ff;
        }
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.memberBaseInfoView {
  position: relative;
  height: 100%;
  padding-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;

  > .memberInfoView {
    display: flex;

    > div {
      width: 215px;
      height: 48px;
      margin-right: 10px;
      background: #f8f8f8;
      border-radius: 6px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      display: flex;
      overflow: hidden;

      > label {
        color: #999;
        margin-right: 10px;
      }

      > input {
        flex: 1;
        background: #f8f8f8;
        border: none;
        outline: none;
        text-align: right;
        font-size: 14px;
        color: #333;
        height: 24px;
        min-width: 0;
        padding: 0;
      }

      > input[disabled] {
        color: #333;
        -webkit-text-fill-color: #333;
        opacity: 1;
      }
    }
  }

  > .cardInfoView {
    margin-top: 30px;
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      height: 48px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .accInfoView {
    margin-top: 18px;
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      height: 48px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .btn-save {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 90px;
    line-height: 40px;
    height: 40px;
    font-size: 15px;
    color: #fff;
    border-radius: 5px;
    background: #3370ff;
    padding: 0;
  }
}

.orderListView {
  position: relative;
  text-align: center;
  padding-top: 10px;

  > .headerView {
    display: flex;
    padding-right: 50px;
    line-height: 44px;
    background: #fff;

    > label {
      flex: 1;
      font-size: 14px;
      color: #333;
    }
  }

  > .listView {
    overflow-x: hidden;
    overflow-y: auto;

    > .listItem {
      position: relative;
      display: flex;
      line-height: 20px;
      padding: 15px 50px 15px 0;
      background: #fff;

      > label {
        flex: 1;
        font-size: 14px;
        color: #333;
      }
    }

    > .listItem:nth-child(odd) {
      background: #f8f8f8;
    }
  }
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}

//动画过程
.payFullscreen-enter {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

.payFullscreen-enter-active {
  animation: payFullscreen-dialog-fade-in 0.9s ease;
}

.payFullscreen-leave-active {
  animation: payFullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes payFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes payFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}
</style>
